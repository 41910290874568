import { all, fork, put, select, takeEvery } from "redux-saga/effects";

import { OwnerAPI, APIError } from "@ai360/core";
import * as actions from "./actions";
import { setApiResult } from "~/core/api/actions";
import { call } from "redux-saga/effects";
import * as accountActions from "../../account/data/actions";
import { fetchTelematicsCounts, fetchIsTelematicsUser } from "~/file-import/actions";
import { AccountAPI, LocalStorageHelpers } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
import { getTheUserGuid } from "~/login/selectors";
import * as authenticationActions from "~/authentication/actions";
import * as windowActions from "~/window/actions";
import { authState } from "../../account/data/auth-state";
import * as johnDeere from "../../account/data/john-deere";
import { windows } from "../../account/data/windows";

// selectors
import { getUser } from "~/login/selectors";
import { ownerSagas as defaultSagas } from "./controller";

const JOHN_DEERE = "MyJohnDeere";
const onsiteEditMode = {
    ADD: "add",
    DELETE: "delete",
    UPDATE: "update",
};
const NO_DATA = "No Data";
const REGISTERED_STATUSES = ["Registered", "Registration Exists."];

export function* requestAddOwner({ payload }) {
    const LoggedInUser = yield select(getUser);
    const requestOptions = { LoggedInUser, Model: payload };
    try {
        const response = yield OwnerAPI.addOwner(requestOptions);
        yield put(actions.addOwnerSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        }
        console.warn(`Failed to process action ${actions.addOwner().type}`);
        yield put(actions.addOwnerFailed(error));
    }
}

export function* watchAddOwner() {
    yield takeEvery(actions.addOwner, requestAddOwner);
}

export function* fetchAccount(action) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const response = yield AccountAPI.getUserAccount({ UserGuid });
        if (response) {
            yield put(accountActions.fetchAccountSuccess(response));
        } else {
            yield put(accountActions.fetchAccountFailure(NO_DATA));
        }
    } catch (error) {
        yield put(accountActions.fetchAccountFailure({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export function* watchFetchAccount() {
    yield takeEvery(accountActions.fetchAccount, fetchAccount);
}

export function* fetchUrlForRegistrationOwner(action) {
    const UserGuid = yield select(getTheUserGuid);
    try {
        const connName = action.payload["onsiteConnectionName"];
        let registrationUrl;
        switch (connName) {
            case JOHN_DEERE:
                registrationUrl = yield johnDeere.fetchRegistrationUrl(
                    UserGuid,
                    authState.johnDeereApi.accessToken
                );
                break;
            default:
                registrationUrl = yield AccountAPI.getUrlForRegistration({
                    UserGuid,
                    Model: action.payload,
                });
        }

        if (registrationUrl) {
            if (
                connName === JOHN_DEERE &&
                !action.payload.isEdit &&
                !REGISTERED_STATUSES.includes(registrationUrl)
            ) {
                yield put(windowActions.open(windows.johnDeereApiAuth, registrationUrl));
                yield put(authenticationActions.listen(authState.johnDeereApi.accessToken));
            }

            yield put(actions.fetchUrlForRegistrationOwnerSuccess(registrationUrl));
        } else {
            yield put(actions.fetchUrlForRegistrationOwnerFailure(NO_DATA));
        }
    } catch (error) {
        yield put(actions.fetchUrlForRegistrationOwnerFailure({ error }));
        yield put(notificationActions.apiCallError(error, action));
    }
}

export function* watchFetchUrlForRegistrationOwner() {
    yield takeEvery(actions.fetchUrlForRegistrationOwner, fetchUrlForRegistrationOwner);
}

export function* saveOwnerTelematics(action) {
    const { onsiteConnection, editMode } = action.payload;

    if (onsiteConnection) {
        const UserGuid = yield select(getTheUserGuid);
        const requestOptions = { UserGuid, Model: onsiteConnection };
        try {
            switch (editMode) {
                case onsiteEditMode.ADD:
                    yield call(AccountAPI.addCompanyOnsiteConnection, requestOptions);
                    break;
                case onsiteEditMode.DELETE:
                    yield call(AccountAPI.deleteCompanyOnsiteConnection, requestOptions);
                    break;
                default:
                    break;
            }
            yield put(actions.saveOwnerTelematicsSuccess());
        } catch (error) {
            if (error instanceof APIError) {
                yield put(setApiResult(error));
            }
            yield put(actions.saveOwnerTelematicsFailed({ error }));
        }
    } else {
        yield put(actions.saveOwnerTelematicsFailed());
    }
}

export function* watchSaveOwnerTelematics() {
    yield takeEvery(actions.saveOwnerTelematics, saveOwnerTelematics);
}

export function* onSaveOwnerTelematicsSuccess() {
    yield put(fetchIsTelematicsUser());
    yield put(fetchTelematicsCounts());
}

export function* watchSaveOwnerTelematicsSuccess() {
    yield takeEvery(actions.saveOwnerTelematicsSuccess, onSaveOwnerTelematicsSuccess);
}

export function* onAuthenticationReceivedForOwner(action) {
    const { name, value } = action;
    if (value == null) {
        return;
    }

    if (name === authState.johnDeereApi.accessToken) {
        yield johnDeere.receivedRefreshToken(value);

        const selectOrganizationsUrl = yield johnDeere.fetchSelectOrganizationsUrl(
            authState.johnDeereApi.selectOrganizations
        );
        yield put(windowActions.redirect(windows.johnDeereApiAuth, selectOrganizationsUrl));
        yield put(authenticationActions.listen(authState.johnDeereApi.selectOrganizations));
    } else if (name === authState.johnDeereApi.selectOrganizations) {
        yield put(windowActions.close(windows.johnDeereApiAuth));
    }

    LocalStorageHelpers.remove(name);
}

export function* watchAuthenticationReceivedForOwner() {
    yield takeEvery(authenticationActions.received, onAuthenticationReceivedForOwner);
}

export const ownerSagas = function* () {
    yield all([
        fork(defaultSagas),
        fork(watchAddOwner),
        fork(watchFetchAccount),
        fork(watchFetchUrlForRegistrationOwner),
        fork(watchSaveOwnerTelematics),
        fork(watchSaveOwnerTelematicsSuccess),
        fork(watchAuthenticationReceivedForOwner),
    ]);
};
