import React, { useEffect, useState } from "react";
import { Section, SelectInput, SubSection } from "~/core";
import { injectIntl } from "react-intl";
import { messages } from "../i18n-messages";
import "./component.css";
import { IFormatter, JohnDeereAPI } from "@ai360/core";

interface IPortalIntegrationProps {
    intl: IFormatter;
    isEditMode: boolean;
    onOrganizationChange: (value: string, records: JohnDeereAPI.IJohnDeereOrganization[]) => void;
    records: JohnDeereAPI.IJohnDeereOrganization[];
    value: string;
}

const PortalIntegration = (props: IPortalIntegrationProps) => {
    const [chosenOrgId, setChosenOrgId] = useState(props.value);
    const { formatMessage } = props.intl;
    const { onOrganizationChange, records } = props;

    useEffect(() => {
        if (props.value !== undefined) {
            setChosenOrgId(props.value);
        }
    }, [props.value]);

    return (
        <Section headerText={formatMessage(messages.portalIntegration)}>
            <SubSection>
                <SelectInput
                    containerClassNames={["portal-integration"]}
                    clearable={true}
                    placeholderText={formatMessage(messages.jdOrganizationName)}
                    value={chosenOrgId}
                    options={records.map(({ name, organizationId }) => ({
                        value: organizationId,
                        label: name,
                    }))}
                    onChange={(value) => {
                        value = !value ? "" : value;
                        setChosenOrgId(value);
                        onOrganizationChange(value, records);
                    }}
                />
            </SubSection>
        </Section>
    );
};

export default injectIntl(PortalIntegration);
