import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import {
    getAgBytesErrorClassNames,
    mapToPicklistValue,
    onTextChange,
    prepareSelectableOptions,
    preventBubbleUp,
} from "~/admin/utils";
import {
    Button,
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    Section,
    SubSection,
    TextArea,
    TextInput,
    ZeroToInfiniteGrid,
    SelectInput,
} from "~/core";
import { ABBREVIATION, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import { PICKLIST_PHONE_TYPE } from "~/core/picklist/picklist-names";
import { model, service } from "../data";
import { messages } from "../../i18n-messages";
import { formatPlainTextLabel } from "~/admin/utils";
import { keywords } from "../keywords";
import { SUCCESS, FAILURE } from "~/hocs/needs/utils";
import { createAddLinkLabelText } from "~/i18n-messages";
import { PersonalityTypes } from "~/utils/keywords";
// Components
import SlidingPanel from "~/sliding-panel/sliding-panel";
import Address from "../../component/address/address";
import Phone from "../../component/phone/phone";
import Urls from "../../component/urls/urls";
import { PersonAffiliationsFlatList } from "../../component/person-affiliations-flat-list";
import AgvanceIntegration from "../../component/agvance-integration";
import EquipmentAddEdit from "../../equipment/add-edit/add-edit-container";

//Style
import "./add-edit-panel.css";
import "./add-edit-responsive.css";
import "../../add-edit-shared-responsive.css";

import { PhotoSimple } from "~/admin/agBytes/components/photo-simple";
import PortalIntegration from "../../component/portal-integration";
import { JohnDeereAPI } from "@ai360/core";

const SVG = "svg";
const ORG_LEVEL_GUID = "orgLevelGuid";
const ORG_LEVEL_NAME = "orgLevelName";

export class AddEditPanel extends Component {
    static propTypes = {
        apiError: PropTypes.object,
        apiErrors: PropTypes.array,
        createEquipment: PropTypes.func,
        equipmentList: PropTypes.array,
        fetchData: PropTypes.bool,
        fetchDropdownData: PropTypes.func,
        fetchOrgLevelEquipmentList: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        getActiveOrgTypeName: PropTypes.func,
        getAgvanceLocations: PropTypes.func,
        getOrgLevel: PropTypes.func,
        getOrgLevelWithAgvanceDatabaseId: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        loggedInUserGuid: PropTypes.string,
        mode: PropTypes.string,
        needs: PropTypes.func,
        orgLevelRecord: PropTypes.object,
        orgTypeName: PropTypes.string,
        parentGuid: PropTypes.string,
        personalityId: PropTypes.number,
        recordGuid: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
    };

    static ADDRESS_TYPE_GUID = "addressTypeGuid";
    static COUNTRY_GUID = "countryGuid";
    static STATE_GUID = "stateGuid";

    constructor(props) {
        super(props);
        this.state = {
            [PICKLIST_PHONE_TYPE]: [],
            [model.PROPS_ORG_LEVEL_ADDRESS_LIST]: [],
            [model.PROPS_ADDRESS_TYPE_LIST]: [],
            [model.PROPS_COUNTRIES_LIST]: [],
            [model.PROPS_PHONE_NUMBER_LIST]: [],
            [model.PROPS_URL_LIST]: [],
            [model.PROPS_PERSON_ORG_LEVEL_LIST]: [],
            showEquipmentScreen: false,
            saveEquipmentData: false,
            equipmentListData: [],
            databaseIdConfirmOpen: false,
            duplicateDatabaseIdOrgLevels: [],
            jdOrganizationIds: [],
            parentGuid: null,
        };
        this.orgLevel = {};
        this.currentAgvanceDatabaseId = null;
        this.equipmentAddRequestId = null;
    }

    _initializeDropdowns = (nextProps) => {
        this._initializeAddressType(nextProps);
        this._initializeCountryList(nextProps);
        this._initializeStateList(nextProps);
        this._initializePhone(nextProps);
        this._initializeDuplicateOrgLevels(nextProps);
        this._initializeAgvanceLocations(nextProps);
        this._initializePortalIntegration(nextProps);
    };

    _initializeAgvanceLocations = (nextProps) => {
        if (nextProps.agvanceLocations !== this.state.agvanceLocations) {
            this.setState({
                agvanceLocations: nextProps.agvanceLocations.map(({ locationGuid, name, id }) => ({
                    value: locationGuid,
                    label: `${id} - ${name}`,
                    activeYn: true,
                    id,
                })),
            });
        }
    };

    _initializePortalIntegration = (nextProps) => {
        if (nextProps?.parentGuid !== this.state.parentGuid) {
            JohnDeereAPI.getOrganizations(nextProps?.parentGuid).then((idObjects) => {
                this.setState({ jdOrganizationIds: idObjects });
            });
            this.setState({ parentGuid: nextProps?.parentGuid });
        }
    };

    _initializeDuplicateOrgLevels = (nextProps) => {
        if (nextProps.duplicateDatabaseIdOrgLevels !== this.state.duplicateDatabaseIdOrgLevels) {
            this.setState({
                duplicateDatabaseIdOrgLevels: prepareSelectableOptions(
                    nextProps.duplicateDatabaseIdOrgLevels,
                    { guid: ORG_LEVEL_GUID, label: ORG_LEVEL_NAME }
                ),
            });
        }
    };

    _initializeAddressType = (nextProps) => {
        if (
            nextProps[model.PROPS_ADDRESS_TYPE_LIST] !== this.state[model.PROPS_ADDRESS_TYPE_LIST]
        ) {
            this.setState({
                [model.PROPS_ADDRESS_TYPE_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_ADDRESS_TYPE_LIST],
                    { guid: AddEditPanel.ADDRESS_TYPE_GUID, label: NAME }
                ),
            });
        }
    };

    _initializeCountryList = (nextProps) => {
        if (nextProps[model.PROPS_COUNTRIES_LIST] !== this.state[model.PROPS_COUNTRIES_LIST]) {
            this.setState({
                [model.PROPS_COUNTRIES_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_COUNTRIES_LIST],
                    { guid: AddEditPanel.COUNTRY_GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    _initializeStateList = (nextProps) => {
        if (nextProps[model.PROPS_STATE_LIST] !== this.state[model.PROPS_STATE_LIST]) {
            this.setState({
                [model.PROPS_STATE_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_STATE_LIST],
                    { guid: AddEditPanel.STATE_GUID, label: ABBREVIATION }
                ),
            });
        }
    };

    _initializePhone = (nextProps) => {
        if (nextProps[PICKLIST_PHONE_TYPE] !== this.state[PICKLIST_PHONE_TYPE]) {
            this.setState({
                [PICKLIST_PHONE_TYPE]: prepareSelectableOptions(nextProps[PICKLIST_PHONE_TYPE], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                }),
            });
        }
    };

    _onTextChange = (formKey, value, callback) => {
        if (formKey === model.PROPS_AGVANCE_DATABASE_ID && !value) {
            this.setState({
                showDuplicateOrgLevels: true,
            });
        }
        this.orgLevel = onTextChange(this.orgLevel, { formKey: [formKey], value }, callback);
    };

    _onOrganizationChange = (value) => {
        this._onTextChange(model.PROPS_PARTNER_ORG_ID, value);
    };

    toggleEquipmentAddEditPanel = () => {
        this.setState({
            showEquipmentScreen: !this.state.showEquipmentScreen,
        });
        this.setHeader();
    };

    toggleEquipmentSave = () => {
        this.setState({
            saveEquipmentData: !this.state.saveEquipmentData,
        });
    };

    onSaveEquipmentData = (data) => {
        this.toggleEquipmentSave();
        if (this.equipmentAddRequestId === null) {
            this.setState(
                {
                    currentEquipmentData: data,
                },
                () => {
                    this.equipmentAddRequestId = this.props.needs([
                        this.props.createEquipment(data),
                    ]);
                }
            );
        }
    };

    onConfirmIdChange = () => {
        this.props.liftRecordData(this.orgLevel);
        this.setState({
            databaseIdConfirmOpen: false,
        });
    };

    processAddEquipmentRequest = (nextProps) => {
        if (nextProps.requestIds[this.equipmentAddRequestId] === SUCCESS) {
            if (this.state.currentEquipmentData) {
                this.setState({ currentEquipmentData: null });
                const { needs } = this.props;
                needs([
                    this.props.fetchOrgLevelEquipmentList(this.orgLevel[model.PROPS_ORG_LEVEL_ID]),
                ]);
            }
            this.equipmentAddRequestId = null;
            this.toggleEquipmentAddEditPanel();
        } else if (nextProps.requestIds[this.equipmentAddRequestId] === FAILURE) {
            this.equipmentAddRequestId = null;
        }
    };

    mapSelectedCountry = ({ options = [], guid }) => {
        if (guid && options.length) {
            return mapToPicklistValue({ options, selectedGuid: guid });
        }
        return null;
    };

    onChildComponentChange = (childKey, value) => {
        this.orgLevel[childKey] = value;
    };

    setHeader = () => this.props.setBreadcrumbs([this.orgLevel[model.PROPS_ORG_LEVEL_NAME]]);

    componentDidMount() {
        this.props.setBreadcrumbs([""]);
        this.orgLevel = service.getDefaultRecord();
        const { needs } = this.props;
        this.orgLevel[model.PROPS_PARENT_ORG_LEVEL_GUID] = this.props.parentGuid;
        needs([
            this.props.fetchDropdownData(),
            this.props.fetchPicklistData(),
            this.props.getActiveOrgTypeName(this.props.parentGuid),
        ]);

        if (this.props.recordGuid) {
            needs([this.props.getOrgLevel(this.props.recordGuid)]);
        }
    }

    _onAgvanceLocationChange = (value) => {
        if (value) {
            this._onTextChange(model.PROPS_AGVANCE_LOCATION_GUID, value);
            const selectedAgvanceLocation = this.state.agvanceLocations.find(
                (l) => l.guid === value
            );
            if (selectedAgvanceLocation) {
                this._onTextChange(model.PROPS_AGVANCE_LOCATION_ID, selectedAgvanceLocation.id);
            }
        } else {
            this._onTextChange(model.PROPS_AGVANCE_LOCATION_GUID, "");
            this._onTextChange(model.PROPS_AGVANCE_LOCATION_ID, "");
        }
    };

    getLogoErrorClassName = (errorCodes = []) => {
        const { apiErrors } = this.props;
        return getAgBytesErrorClassNames(errorCodes, apiErrors).length
            ? "photo-simple-error"
            : null;
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.orgLevel);
        }

        if (
            nextProps.mode === keywords.EDIT &&
            nextProps.orgLevelRecord !== this.props.orgLevelRecord
        ) {
            this.orgLevel = nextProps.orgLevelRecord;
            this.currentAgvanceDatabaseId = nextProps.orgLevelRecord.agvanceDatabaseId;
            this.props.needs([
                this.props.getOrgLevelWithAgvanceDatabaseId(
                    this.orgLevel[model.PROPS_ORG_LEVEL_ID]
                ),
                this.props.getAgvanceLocations(this.orgLevel[model.PROPS_ORG_LEVEL_ID]),
            ]);
            this.setState({
                [model.PROPS_ORG_LEVEL_ADDRESS_LIST]:
                    nextProps.orgLevelRecord[model.PROPS_ORG_LEVEL_ADDRESS_LIST],
                [model.PROPS_ORG_LEVEL_PERSON_LIST]:
                    nextProps.orgLevelRecord[model.PROPS_ORG_LEVEL_PERSON_LIST],
                [model.PROPS_PHONE_NUMBER_LIST]:
                    nextProps.orgLevelRecord[model.PROPS_PHONE_NUMBER_LIST],
                [model.PROPS_URL_LIST]: nextProps.orgLevelRecord[model.PROPS_URL_LIST],
                equipmentListData: nextProps.orgLevelRecord[model.PROPS_ORG_LEVEL_EQUIPMENT_LIST],
            });
        }
        if (nextProps.mode === keywords.EDIT && nextProps.equipmentList.length) {
            this.orgLevel[model.PROPS_ORG_LEVEL_EQUIPMENT_LIST] = nextProps.equipmentList;
            this.setState({
                equipmentListData: nextProps.equipmentList,
            });
        }
        this._initializeDropdowns(nextProps);
        this.processAddEquipmentRequest(nextProps);
    }

    _renderEquipmentAddEdit = () => {
        const primaryAddress = this.orgLevel[model.PROPS_ORG_LEVEL_ADDRESS_LIST].filter(
            ({ isPrimary }) => isPrimary === true
        );
        const ownerOperatorData = {
            ownerOperatorOrgLevelGuid: this.orgLevel[model.PROPS_ORG_LEVEL_ID],
            ownerOperatorName: this.orgLevel[model.PROPS_ORG_LEVEL_NAME],
            ownerOperatorCity: primaryAddress[0].theAddress.city,
            ownerOperatorStateAbbreviation: primaryAddress[0].theAddress.stateAbbreviation,
        };
        return (
            <div className="equipment-add-edit-page">
                <EquipmentAddEdit
                    addEditPanel={{
                        mode: keywords.ADD,
                    }}
                    needs={this.props.needs}
                    ownerOperatorData={ownerOperatorData}
                    fetchData={this.state.saveEquipmentData}
                    liftRecordData={this.onSaveEquipmentData}
                />
            </div>
        );
    };

    _renderDetailInfo1 = () => {
        const { needs, apiErrors } = this.props;
        const { onChildComponentChange, mapSelectedCountry } = this;
        const { mode } = this.props;
        const { formatMessage } = this.props.intl;
        const props = {
            onChildComponentChange,
            mapSelectedCountry,
            addEditPanel: { mode },
            needs,
            apiErrors,
        };
        return (
            <div className="section-container section-column">
                <Section>
                    <SubSection>
                        <Address
                            addressList={this.state[model.PROPS_ORG_LEVEL_ADDRESS_LIST]}
                            addressTypeList={this.state[model.PROPS_ADDRESS_TYPE_LIST]}
                            countriesList={this.state[model.PROPS_COUNTRIES_LIST]}
                            stateList={this.state[model.PROPS_STATE_LIST]}
                            addressPropKey={model.PROPS_ORG_LEVEL_ADDRESS_LIST}
                            {...props}
                        />
                    </SubSection>
                </Section>
                {this.orgLevel[model.PROPS_CAN_EDIT_REPORT_LOGO] ? (
                    <Section headerText={formatMessage(messages.reportLogo)}>
                        <SubSection>
                            <PhotoSimple
                                className={this.getLogoErrorClassName([2869])}
                                record={this.orgLevel[model.PROPS_REPORT_LOGO]}
                                formatMessage={formatMessage}
                                itemListAlias={model.PROPS_REPORT_LOGO}
                                onChange={(value) => {
                                    this._onTextChange([model.PROPS_REPORT_LOGO], value);
                                }}
                                title={formatMessage(messages.recommendedImageSize)}
                                id={model.PROPS_SYSTEM_LOGO_DARK}
                                fileType={SVG}
                            />
                        </SubSection>
                    </Section>
                ) : null}
            </div>
        );
    };

    _renderDetailInfo2 = () => {
        const { formatMessage } = this.props.intl;
        const { mode, apiErrors } = this.props;
        const props = { addEditPanel: { mode }, apiErrors };
        return (
            <div className="section-container section-column">
                <Section>
                    {this.state[model.PROPS_PERSON_ORG_LEVEL_LIST].length === 0 ? null : (
                        <Section
                            headerText={formatMessage(messages.person)}
                            className="grid-section"
                        >
                            <PersonAffiliationsFlatList
                                record={this.state[model.PROPS_PERSON_ORG_LEVEL_LIST]}
                                statePropName={model.PROPS_STATE_ABBREVIATION}
                            />
                        </Section>
                    )}
                    <SubSection>
                        <Phone
                            phoneList={this.state[model.PROPS_PHONE_NUMBER_LIST]}
                            phoneTypeList={this.state[PICKLIST_PHONE_TYPE]}
                            onChildComponentChange={this.onChildComponentChange}
                            phonePropKey={model.PROPS_PHONE_NUMBER_LIST}
                            {...props}
                        />
                    </SubSection>
                    <SubSection>
                        <Urls
                            urlList={this.state[model.PROPS_URL_LIST]}
                            urlListAlias={model.PROPS_URL_LIST}
                            onChildComponentChange={this.onChildComponentChange}
                            {...props}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    _renderDetailInfo3 = () => {
        const { formatMessage } = this.props.intl;
        const notesLabel = formatMessage(messages.notes);

        return (
            <div className="section-container section-column">
                {this.props.mode !== keywords.EDIT ? null : (
                    <Section
                        className="grid-section equipment-aff-grid"
                        headerText={formatMessage(messages.equipment)}
                    >
                        {!(this.state.equipmentListData.length > 0) ? null : (
                            <ZeroToInfiniteGrid
                                records={this.state.equipmentListData}
                                columns={{
                                    equipmentName: {
                                        title: formatMessage(messages.name),
                                        width: 50,
                                    },
                                    equipmentParentType: {
                                        title: formatMessage(messages.parentType),
                                        width: 75,
                                    },
                                    equipmentType: {
                                        title: formatMessage(messages.typeName),
                                        width: 75,
                                    },
                                    manufacturer: {
                                        title: formatMessage(messages.manufacturer),
                                        width: 75,
                                    },
                                }}
                            />
                        )}
                        <div className="add-link-container">
                            <NoLink
                                className="add-link"
                                label={createAddLinkLabelText(formatMessage, messages.equipment)}
                                onClick={this.toggleEquipmentAddEditPanel}
                            />
                        </div>
                    </Section>
                )}
                <Section headerText={notesLabel} className="grid-section">
                    <SubSection>
                        <TextArea
                            placeholderText={notesLabel}
                            labelText={notesLabel}
                            value={this.orgLevel[model.PROPS_NOTES]}
                            onChange={(value) => this._onTextChange(model.PROPS_NOTES, value)}
                        />
                    </SubSection>
                </Section>
                {!(this.props.personalityId !== PersonalityTypes.DISCONNECTED) ? null : (
                    <div>
                        <AgvanceIntegration
                            aliasList={{
                                databaseId: model.PROPS_AGVANCE_DATABASE_ID,
                                userId: model.PROPS_AGVANCE_USER_ID,
                                userPassword: model.PROPS_AGVANCE_USER_PASSWORD,
                                requireCustomerSearchFlag: model.PROP_REQUIRE_CUSTOMER_SEARCH_FLAG,
                                isAgvanceCustomerExists: model.PROPS_IS_AGVANCE_CUSTOMER_EXISTS,
                            }}
                            isAgvancePersonality={
                                this.props.personalityId === PersonalityTypes.AGVANCE_STANDARD
                            }
                            record={this.orgLevel}
                            isEditMode={this.props.mode === keywords.EDIT}
                            onTextChange={this._onTextChange}
                        />
                        {!this.orgLevel[model.PROPS_AGVANCE_DATABASE_ID] ? null : (
                            <Section>
                                <SubSection>
                                    <SelectInput
                                        clearable
                                        options={this.state.agvanceLocations}
                                        value={this.orgLevel[model.PROPS_AGVANCE_LOCATION_GUID]}
                                        onChange={this._onAgvanceLocationChange}
                                        placeholderText={formatMessage(messages.agvanceLocation)}
                                        containerClassNames={["migrate-to-customer-select"]}
                                    />
                                </SubSection>
                            </Section>
                        )}
                        {!this.state.showDuplicateOrgLevels ? null : (
                            <Section>
                                <SubSection>
                                    <SelectInput
                                        clearable={false}
                                        options={this.state.duplicateDatabaseIdOrgLevels}
                                        value=""
                                        onChange={(value) => {
                                            this._onTextChange(
                                                model.PROPS_UPDATED_AGVANCE_ORG_LEVEL_GUID,
                                                value.guid
                                            );
                                        }}
                                        placeholderText={formatMessage(
                                            messages.migrateCustomerToLocation
                                        )}
                                        containerClassNames={["migrate-to-customer-select"]}
                                        required
                                    />
                                </SubSection>
                            </Section>
                        )}
                        <DialogBox
                            isOpen={this.state.databaseIdConfirmOpen}
                            footerType={DialogBoxFooterType.YES_NO}
                            onAction={() => this.onConfirmIdChange()}
                            title={formatMessage(messages.databaseIdChange)}
                        >
                            {formatMessage(messages.databaseIdChangeMsg)}
                        </DialogBox>
                    </div>
                )}
                {this.state.jdOrganizationIds?.length === 0 ||
                !this.props.orgTypeName.includes("Location") ? null : (
                    <PortalIntegration
                        records={this.state.jdOrganizationIds}
                        value={this.orgLevel[model.PROPS_PARTNER_ORG_ID]}
                        isEditMode={this.props.mode === keywords.EDIT}
                        onOrganizationChange={this._onOrganizationChange}
                    />
                )}
            </div>
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { apiErrors } = this.props;
        return (
            <div className="add-edit-panel org-level-details">
                <Section>
                    {this.props.mode !== keywords.EDIT ? null : (
                        <SubSection>
                            <div className="form-input-id">
                                <span>
                                    {formatPlainTextLabel(
                                        formatMessage,
                                        messages.locationID,
                                        this.orgLevel[model.PROPS_LOCATION_ID]
                                    )}
                                </span>
                            </div>
                        </SubSection>
                    )}
                    <SubSection>
                        <TextInput
                            maxLength={50}
                            tabIndex={0}
                            placeholderText={this.props.orgTypeName}
                            labelText={this.props.orgTypeName}
                            onChange={(value) =>
                                this._onTextChange(
                                    model.PROPS_ORG_LEVEL_NAME,
                                    value,
                                    this.setHeader
                                )
                            }
                            value={this.orgLevel[model.PROPS_ORG_LEVEL_NAME]}
                            containerClassNames={getAgBytesErrorClassNames(43, apiErrors, [
                                "org-type-name",
                            ])}
                            required
                        />
                        {this.props.mode !== keywords.EDIT ? null : (
                            <Checkbox
                                label={formatMessage(messages.active)}
                                onChange={(e, value) =>
                                    this._onTextChange(model.PROPS_ORG_LEVEL_ACTIVE_YN, value)
                                }
                                value={this.orgLevel[model.PROPS_ORG_LEVEL_ACTIVE_YN]}
                            />
                        )}
                    </SubSection>
                </Section>
                <div className="section-container">
                    {this._renderDetailInfo1()}
                    <span className="bar section-fence" />
                    {this._renderDetailInfo2()}
                    <span className="bar section-fence" />
                    {this._renderDetailInfo3()}
                </div>
                {!this.state.showEquipmentScreen ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            component={this._renderEquipmentAddEdit}
                            navigateTo={{
                                parentNameCode: 101,
                                childNameCode: 140,
                            }}
                        >
                            <Button type="save" forceSubmit onClick={this.toggleEquipmentSave} />
                            <Button type="cancel" onClick={this.toggleEquipmentAddEditPanel} />
                        </SlidingPanel>
                    </form>
                )}
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
